var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// form
import { FormProvider as Form } from 'react-hook-form';
export default function FormProvider(_a) {
    var children = _a.children, onSubmit = _a.onSubmit, methods = _a.methods, className = _a.className, style = _a.style;
    return (_jsx(Form, __assign({}, methods, { children: _jsx("form", __assign({ onSubmit: onSubmit, className: className, style: style }, { children: children })) })));
}
