var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
import { Tooltip } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useLocales } from '../../locales';
export default function RHFPhoneNumber(_a) {
    var name = _a.name, helperText = _a.helperText, label = _a.label;
    var translate = useLocales().translate;
    var control = useFormContext().control;
    var handleWheel = function (event) {
        event.preventDefault();
    };
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(Tooltip, __assign({ title: "".concat(translate(helperText)) || "".concat(translate(label)) }, { children: _jsx("div", { children: _jsx(MuiPhoneNumber, { sx: { '& svg': { height: '1em' } }, variant: "outlined", name: name, label: "".concat(translate(label), " "), onBlur: field.onBlur, InputProps: {
                            onWheel: handleWheel,
                        }, fullWidth: true, onWheel: handleWheel, value: field.value, inputProps: {
                            ref: field.ref,
                        }, countryCodeEditable: true, onChange: field.onChange, error: !!error, helperText: error && "".concat(translate(error === null || error === void 0 ? void 0 : error.message)), defaultCountry: "tn" }) }) })));
        } }));
}
